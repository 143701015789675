import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Global error handler for JavaScript errors
function GlobalErrorHandler({ children }) {
  const navigate = useNavigate();
  const [hasGlobalError, setHasGlobalError] = useState(false);

  useEffect(() => {
    const handleGlobalError = () => {
      setHasGlobalError(true);
    };

    window.onerror = handleGlobalError;
    return () => {
      window.onerror = null; // Cleanup
    };
  }, []);

  const handleGoBack = () => {
    navigate(-1);
    setHasGlobalError(false);
  };

  if (hasGlobalError) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '75vh',
        }}
      >
        <h3>Oops! A global error occurred.</h3>
        <h1
          style={{
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={handleGoBack}
        >
          Go Back
        </h1>
      </div>
    );
  }

  return children;
}

// React Error Boundary
class ReactErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('React Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '75vh',
          }}
        >
          <h3>Oops! Something went wrong in React.</h3>
          <h1
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => window.location.reload()}
          >
            Reload Page
          </h1>
        </div>
      );
    }

    return this.props.children;
  }
}

// Combined Wrapper Component
function ErrorBoundaryWrapper({ children }) {
  return (
    <GlobalErrorHandler>
      <ReactErrorBoundary>{children}</ReactErrorBoundary>
    </GlobalErrorHandler>
  );
}

export default ErrorBoundaryWrapper;