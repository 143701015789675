import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
} from '@windmill/react-ui';
import { AddIcon, EditIcon } from '../../../assets/asset';
import { isEmpty } from 'lodash';

const Modals = ({icon='',className='',...props}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <>
      <div>
        {icon === 'Add' && (
          <Label
            className="px-4 py-3 my-1 text-blue-500 hover:cursor-pointer"
            onClick={openModal}
          >
            <AddIcon className="w-5 h-5 mr-2 inline" aria-hidden="true" />
            {props.iconTitle}
          </Label>
        )}
        {icon === 'Edit' && (
          <Label
            className={`${className} px-4 py-3 my-1 text-blue-500 hover:cursor-pointer`}
            onClick={openModal}
          >
            <EditIcon className="w-5 h-5 mr-2 inline" aria-hidden="true" />
            {props.iconTitle}
          </Label>
        )}
        {isEmpty(icon) && (
          <Button onClick={openModal}>{props.title}</Button>
        )}
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>{props.children}</ModalBody>
        <ModalFooter>
          <>
            <div className="sm:block hidden ">
              <Button layout="outline" onClick={closeModal}>
                Cancel
              </Button>
            </div>
            <div className="sm:block hidden ">
              <Button form={props.formId} type="submit">
                Save
              </Button>
            </div>
            <div className="block w-full sm:hidden">
              <Button block size="large" layout="outline" onClick={closeModal}>
                Cancel
              </Button>
            </div>
            <div className="block w-full sm:hidden">
              <Button block form={props.formId} size="large" type="sumbit">
                Save
              </Button>
            </div>
          </>
        </ModalFooter>
      </Modal>
    </>
  );
};



Modals.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
  footerButtons: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.bool,
  ]),
};

export default Modals;
